<template>
  <div style="padding-bottom: 68px;">
    <div class="fix-head flex-between">
      <div class="flex-center">
        <img src="../../assets/order_logo.png" alt="logo" width="28px">
        <h1 class="title">订单编号：{{detail.ordersn}}</h1>
      </div>
      <div class="overview flex">
        <div class="overview-item flex">
          <span class="overview-label">状态</span>
          <span class="overview-content">{{detail.statusname}}</span>
        </div>
        <div class="overview-item flex">
          <span class="overview-label">订单金额</span>
          <span class="overview-content">￥{{detail.amount / 100}}</span>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="head">收货信息</div>
      <div class="main flex-between">
        <div class="main-item">
          <span class="label">收货人：</span>
          <span class="contain">{{detail.contactor}}</span>
        </div>
        <div class="main-item">
          <span class="label">联系方式：</span>
          <span class="contain">{{detail.mobile}}</span>
        </div>
        <div class="main-item flex">
          <span class="label">联系地址：</span>
          <span class="contain">{{detail.address}}</span>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="head">订单信息</div>
      <div class="main flex-between">
        <div class="main-item">
          <span class="label">订单编号：</span>
          <span class="contain">{{detail.ordersn}}</span>
        </div>
        <div class="main-item" />
        <div class="main-item" />
        <div class="main-item">
          <span class="label">订单状态：</span>
          <span class="contain">{{detail.statusname}}</span>
        </div>
        <div class="main-item">
          <span class="label">物流编号：</span>
          <span class="contain">{{detail.delivery_sn}}</span>
        </div>
        <div class="main-item">
          <span class="label">物流公司：</span>
          <span class="contain">{{detail.delivery_company}}</span>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="head">商品信息</div>
      <div class="main">
        <div class="product" v-for="(item, index) in detail.items" :key="index">
          <div class="product-name">商品{{index + 1}}</div>
          <div class="flex-between" style="flex-wrap: wrap;">
            <div class="main-item">
              <span class="label">商品名称：</span>
              <span class="contain">{{item.product_name}}</span>
            </div>
            <div class="main-item" />
            <div class="main-item" />
            <div class="main-item">
              <span class="label">规格属性：</span>
              <span class="contain">{{item.sku_name}}</span>
            </div>
            <div class="main-item">
              <span class="label">实付价格：</span>
              <span class="contain">{{item.realPay}}</span>
            </div>
          </div>
        </div>
        <el-divider class="divider"></el-divider>
      </div>
    </div>
    <div class="fix-confirm" v-if="detail.status === 0">
      <el-button class="tb-button" @click="onCancel">取消</el-button>
      <el-button class="tb-button" @click="submit(2)">拒绝</el-button>
      <el-button class="tb-button" type="primary" @click="submit(1)">通过</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderDetail',
  data() {
    return {
      id: null,
      detail: {},
      input: '',
      value: ''
    }
  },
  mounted() {
    this.id = this.$route.query.id * 1 || null
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    async getDetail() {
      const url = `admin/order/refund/detail?id=${this.id}`
      const data = await this.$https.get(url)
      if (!data) return
      data.items.forEach(item => {
        item.realPay = this.$np.divide(item.pay_price, 100)
      })
      data.statusname = ['待审核', '已通过', '已拒绝'][data.status]
      this.detail = data
    },
    async submit(status) {
      this.$confirm(`是否${status === 2 ? '拒绝' : '通过'}?`, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          id: this.id,
          status
        }
        const url = 'admin/order/refund/edit'
        if (status === 2) {
          this.$prompt('请输入拒绝理由', '', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(({ value }) => {
            params.reason = value
            this.$https.post(url, params).then(data => {
              if (data) this.$router.push('/refund/list')
            })
          }).catch(() => {})
        } else {
          this.$https.post(url, params).then(data => {
            if (data) this.$router.push('/refund/list')
          })
        }
      }).catch(() => {})
    },
    onCancel() {
      this.$router.push('/refund/list')
    }
  }
}
</script>

<style scoped lang="scss">
.fix-head {
  padding-bottom: 24px;
  .title {
    padding-left: 16px;
  }
  .overview {
    text-align: right;
    &-item {
      flex-direction: column;
      .overview-label {
        color: rgba(0, 0, 0, 0.45);
        padding-bottom: 4px;
      }
      .overview-content {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .overview-item+.overview-item {
      margin-left: 40px;
    }
  }
}
.item {
  margin: 24px 24px 0;
  background-color: #fff;
  .head {
    padding: 16px 32px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #E9E9E9;
    box-sizing: border-box;
  }
  .main {
    padding: 24px 32px;
    flex-wrap: wrap;
    &-item {
      width: 33%;
      margin-top: 16px;
      .label {
        min-width: 70px;
        color: rgba(0, 0, 0, 0.85);
      }
      .contain {
        min-width: 300px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .main-item:nth-child(-n+3) {
      margin-top: 0;
    }
    .product {
      &-name {
        padding-bottom: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .divider {
      margin: 32px 0;
    }
    .no-info {
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
</style>
